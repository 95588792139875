import React from 'react';
import { Link, Element, animateScroll as scroll } from 'react-scroll';
import Hero from './Hero';
import CtaButtons from './components/CtaButtons';
import About from './About';
import Skills from './Skills';
import Work from './Work';
import Contact from './Contact';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Analytics } from '@vercel/analytics/react';

function App() {
  return (
    <div>
      <CtaButtons />
      <Element name='hero'>
        <Hero />
      </Element>
      <Element name='about'>
        <About />
      </Element>
      <Element name='skills'>
        <Skills />
      </Element>
      <Element name='work'>
        <Work />
      </Element>
      <Element name='contact'>
        <Contact />
      </Element>
      <Analytics />
      <SpeedInsights />
    </div>
  );
}

export default App;
