import React from 'react';

const Skills = () => {
    return (
        <section id="skills">
            <h1>These are my skills section</h1>
        </section>
    );
};

export default Skills;