import React from 'react';

const EmailSvg = React.forwardRef((props, ref) => {
    return (
        <svg className="email-svg" viewBox="0 0 100 125" xmlns="http://www.w3.org/2000/svg" ref={ref}>
            <path d="M92.6,20.8l-0.1-0.2c3-3.5,3.3-8.8,0.3-12.6l-1.2-1.6c-0.3-0.4-0.8-0.7-1.3-0.8c-0.5-0.1-1,0.1-1.5,0.4L73,18.3H27L11.2,6.1  c-0.4-0.3-1-0.5-1.5-0.4C9.2,5.8,8.8,6,8.5,6.5L7.2,8c-1.6,2.1-2.3,4.7-2,7.4c0.2,2,1.1,3.8,2.3,5.3l-0.1,0.2  c-3,3.8-2.7,9.1,0.3,12.7l-0.1,0.2C4.2,38,5,44.3,9.4,47.7l9.7,7.4c0.5,3,3.2,5.2,6.4,5.2h49.1c3.2,0,5.9-2.3,6.4-5.2l9.7-7.4  c4.4-3.4,5.2-9.7,1.8-14l-0.1-0.1C95.3,30,95.5,24.7,92.6,20.8z M25.5,22.3h49.1c0.6,0,1.1,0.2,1.5,0.5c-0.3,0.2-0.6,0.4-1,0.6  c-6.2,3.8-21,13-25,15.5L25,23.4l-1-0.6C24.4,22.5,24.9,22.3,25.5,22.3z M19,50l-7.2-5.5c-2.6-2-3.1-5.8-1.1-8.4l2.4,1.8l5.8,4.5V50  z M19,37.4l-7.3-5.7c-0.5-0.4-0.9-0.8-1.3-1.3c-1.3-2-1.4-4.6-0.1-6.6c0.1-0.2,0.2-0.3,0.3-0.4l8.4,6.5V37.4z M20.7,20.4  c-0.6,0.6-1.1,1.4-1.3,2.3c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0.1,0,0.2-0.1,0.2c-0.1,0.5-0.2,1-0.2,1.4v0.2l-5.6-4.3l-2-1.5  c-2.6-2-3.1-5.8-1.1-8.4l11.5,8.9C21.4,19.7,21,20,20.7,20.4z M77,54.1c0,1.2-1.1,2.2-2.5,2.2H25.5c-1.4,0-2.5-1-2.5-2.2V26.9L49,43  c0.3,0.2,0.7,0.3,1,0.3s0.7-0.1,1-0.3c0,0,18.7-11.6,26-16.1V54.1z M88.2,44.5L81,50v-7.5l5.8-4.5l2.4-1.8c0.9,1.1,1.3,2.4,1.3,3.7  C90.5,41.6,89.7,43.3,88.2,44.5z M89.6,30.4c-0.3,0.5-0.8,1-1.3,1.3L81,37.4v-7.6l8.4-6.5c0.1,0.1,0.2,0.3,0.3,0.4  C91,25.8,91,28.4,89.6,30.4z M88.5,18.9l-2,1.5L81,24.8v-0.2c0-0.5-0.1-1.1-0.3-1.7c0,0,0,0,0-0.1c0,0,0-0.1-0.1-0.1  c-0.3-0.9-0.8-1.6-1.3-2.3c-0.3-0.4-0.8-0.7-1.2-1l11.5-8.9C91.6,13.1,91.2,16.9,88.5,18.9z M52,74.3v18c0,1.1-0.9,2-2,2s-2-0.9-2-2  v-18c0-1.1,0.9-2,2-2S52,73.2,52,74.3z M62,68.3v18c0,1.1-0.9,2-2,2s-2-0.9-2-2v-18c0-1.1,0.9-2,2-2S62,67.2,62,68.3z M42,68.3v18  c0,1.1-0.9,2-2,2s-2-0.9-2-2v-18c0-1.1,0.9-2,2-2S42,67.2,42,68.3z"/>
        </svg>
    );
});

export default EmailSvg;