import React, { useState, useRef, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import EmailSvg from './svg/EmailSvg';

gsap.registerPlugin(MotionPathPlugin);

// Validation Schema
const ContactSchema = Yup.object().shape({
    name: Yup.string()
        .min(1, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Required'),
    subject: Yup.string()
        .min(2, 'Too Short!')
        .required('Required'),
    message: Yup.string()
        .min(10, 'Too Short!')
        .required('Required'),
});

const ContactForm = () => {
    const [formStatus, setFormStatus] = useState('');
    const [showForm, setShowForm] = useState(true);
    const emailSvgRef = useRef(null);

    useEffect(() => {
        if (!showForm) { // Check if the form is not shown, indicating successful submission
            setTimeout(() => {
                const viewportWidth = window.innerWidth / 2;
                const viewportHeight = window.innerHeight / 2;

                gsap.to(emailSvgRef.current, {
                    duration: 5, // Duration of the entire animation
                    ease: "power1.inOut",
                    motionPath: {
                        path: [
                            {x: viewportWidth, y: viewportHeight}, // Start from the middle
                            {x: "+=100", y: "-=50"}, // First move
                            {x: "-=150", y: "-=100"}, // Second move
                            {x: "+=250", y: "-=50"}, // Final move out of viewport
                        ],
                        autoRotate: true
                    },
                    onComplete: () => {
                        gsap.to(emailSvgRef.current, {
                            autoAlpha: 0, // Make the SVG disappear at the end of the animation
                            duration: 0.5
                        });
                    }
                });
            }, 0);
        }
    }, [showForm]);
    return (
        <Formik
            initialValues={{name: '', email: '', subject: '', message: ''}}
            validationSchema={ContactSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                fetch('http://localhost:5000/send-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        setShowForm(false);
                        setFormStatus('Email sent successfully: ' + data.message);
                        setSubmitting(false);
                        resetForm();
                    })
                    .catch((error) => {
                        setFormStatus(`Error sending email: ${error.toString()}`);
                        setSubmitting(false);
                    });
            }}
        >

            {({isSubmitting, errors, touched}) => (
                showForm ? (
                <Form aria-labelledby="contactFormLabel">
                    <h2 id="contactFormLabel">Get in touch</h2>
                    {formStatus && <div className="form-status">{formStatus}</div>}
                    <div className={`form-group ${errors.name && touched.name ? 'form-group-error' : ''}`}>
                        <label htmlFor="name">Name</label>
                        <ErrorMessage name="name" component="div" className="field-error"/>
                        <Field id="name" name="name" placeholder="Your Name"/>
                    </div>

                    <div className={`form-group ${errors.email && touched.email ? 'form-group-error' : ''}`}>
                        <label htmlFor="email">Email</label>
                        <ErrorMessage name="email" component="div" className="field-error"/>
                        <Field id="email" name="email" type="email" placeholder="Your Email"/>
                    </div>

                    <div className={`form-group ${errors.subject && touched.subject ? 'form-group-error' : ''}`}>
                        <label htmlFor="subject">Subject</label>
                        <ErrorMessage name="subject" component="div" className="field-error"/>
                        <Field id="subject" name="subject" placeholder="Subject"/>
                    </div>

                    <div className={`form-group ${errors.message && touched.message ? 'form-group-error' : ''}`}>
                        <label htmlFor="message">Message</label>
                        <ErrorMessage name="message" component="div" className="field-error"/>
                        <Field id="message" name="message" as="textarea" rows="10" placeholder="Your Message"/>
                    </div>

                    <button type="submit" disabled={isSubmitting}>
                        Send Message
                    </button>
                </Form>
                ) : (
                    <>
                        <div className="success-message animate-in">Thanks for getting in touch!
                            <br/>
                            I'll get back to you shortly 😀</div>
                        <EmailSvg ref={emailSvgRef}/>
                    </>
                )
            )}
        </Formik>
    );
};

export default ContactForm;
