import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-scroll';


const CtaButtons = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null); // Reference to the menu overlay
    const [shouldAnimate, setShouldAnimate] = useState(false); // New state to control animation

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const toggleMenu = () => {
        if (!isMenuOpen) {
            setIsMenuOpen(true);
            // We delay the animation trigger to ensure the element is in the DOM
            requestAnimationFrame(() => {
                setShouldAnimate(true);
            });
        } else {
            setShouldAnimate(false);
            // Delay the closing to allow for the animation to complete
            setTimeout(() => {
                setIsMenuOpen(false);
            }, 500); // Match your transition duration
        }
    };
    // Close the menu if the click is outside of the menu
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setIsMenuOpen(false);
        }
    };



    // Add event listener when the component mounts and remove on unmount
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <div className="cta-buttons">
            <button onClick={toggleMenu} className="menu-toggle">menu</button>
            {isMenuOpen && (
                <div className={`menu-overlay ${shouldAnimate ? 'menu-overlay--visible' : ''}`} ref={menuRef}>
                    <button onClick={toggleMenu} className="menu-close">close</button>
                    <Link to="hero" spy={true} smooth={true} offset={-70} duration={500} onClick={toggleMenu}>home</Link>
                    <Link to="about" spy={true} smooth={true} offset={-70} duration={500} onClick={toggleMenu}>about</Link>
                    <Link to="skills" spy={true} smooth={true} offset={-70} duration={500} onClick={toggleMenu}>skills</Link>
                    <Link to="work" spy={true} smooth={true} offset={-70} duration={500} onClick={toggleMenu}>work</Link>
                    <Link to="contact" spy={true} smooth={true} offset={-70} duration={500} onClick={toggleMenu}>contact</Link>
                </div>
            )}
            <button onClick={toggleModal} className="cta-button">get in touch</button>
            {isModalOpen && (
                <div className="modal" style={{position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '1001', background: 'white', padding: '20px', borderRadius: '5px'}}>
                    {/* Modal content here */}
                    <button onClick={toggleModal}>Close</button>
                </div>
            )}
        </div>
    );
};

export default CtaButtons;
