import React, { useEffect, useRef, useState } from 'react';
import CloudSvg from './components/svg/CloudSvg'; // Adjust the path as necessary
import LilianaSvg from './components/svg/LilianaSvg'; // Adjust the path as necessary
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

gsap.registerPlugin(MotionPathPlugin);

const Hero = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const lilianaRef = useRef(null); // Reference to the Liliana SVG
  const nameRef = useRef(null); // Reference to the name text

  const Hill1Ref = useRef(null);
  const Hill2Ref = useRef(null);

  const heroSectionRef = useRef(null);

  useEffect(() => {
    const currentHeroSection = heroSectionRef.current;
    // Define GSAP timelines for each wave with yoyo effect for back and forth animation
    const Hill1Timeline = gsap
      .timeline({ paused: true, repeat: -1, yoyo: true })
      .to(Hill1Ref.current, { x: '+=25', duration: 3, ease: 'sine.inOut' });
    const Hill2Timeline = gsap
      .timeline({ paused: true, repeat: -1, yoyo: true })
      .to(Hill2Ref.current, { x: '-=10', duration: 2, ease: 'sine.inOut' });

    // Function to start animations
    const startAnimations = () => {
      Hill1Timeline.play();
      Hill2Timeline.play();
    };

    // Function to stop animations
    const stopAnimations = () => {
      Hill1Timeline.pause();
      Hill2Timeline.pause();
    };

    // Intersection Observer to play/pause animations based on visibility
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            startAnimations();
          } else {
            stopAnimations();
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold to when you want the animation to start/stop
    );

    setIsLoaded(true); // Trigger the animations when the component mounts

    const tl = gsap.timeline();

    // Animate the Liliana SVG along the motion path
    tl.to(lilianaRef.current, {
      duration: 3,
      ease: 'power1.inOut',
      motionPath: {
        path: [
          { x: 0, y: 0 },
          { x: 160, y: -100 },
        ],
        curviness: 3,
        autoRotate: true,
      },
    });

    // After the motion path animation completes, start fading in the name
    // Adjust the delay to ensure it starts after the SVG animation
    tl.to(
      nameRef.current,
      {
        duration: 1, // Control the fade-in duration of the name
        opacity: 1,
        ease: 'power1.inOut',
      },
      '>'
    ); // Use ">" to indicate this animation should start right after the previous one

    // Continue with the rotation of the Liliana SVG
    tl.to(lilianaRef.current, {
      duration: 15,
      ease: 'none', // Use none for linear animation without any easing
      rotation: '+=360', // Increment the rotation by 360 degrees
      transformOrigin: '50% 50%', // Set the rotation origin to the center of the SVG
      repeat: -1, // Repeat the animation indefinitely
    });

    if (
      currentHeroSection &&
      currentHeroSection.getBoundingClientRect().top < window.innerHeight
    ) {
      startAnimations();
    }

    return () => {
      stopAnimations(); // Ensure animations are stopped to prevent memory leaks
      if (currentHeroSection) {
        observer.unobserve(currentHeroSection);
      }
    };
  }, []);

  return (
    <section id='hero' ref={heroSectionRef}>
      <div className={`heading ${isLoaded ? 'fadeInUp' : ''}`}>
        <LilianaSvg ref={lilianaRef} />
        <h2 ref={nameRef} className='web-developer'>
          Liliana Summers
        </h2>

        <CloudSvg />
        <h3>crafting digital dreams</h3>
      </div>
      <h4 className={`sub-heading ${isLoaded ? 'fadeInUp' : ''}`}>
        into reality
      </h4>
      <div className='custom-shape-divider-bottom-1708512159'>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
        >
          <path
            ref={Hill1Ref}
            d='M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z'
            opacity='1'
            className='shape-fill-mint'
          ></path>
          <path
            ref={Hill2Ref}
            d='M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z'
            className='shape-fill-black'
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default Hero;
