import React, { useEffect, useRef } from 'react';
import ContactForm from './components/ContactForm';
import { gsap } from 'gsap';

const Contact = () => {
    const handleFormSubmit = (formData) => {
        console.log('Form data:', formData);
        // Here you would typically send the form data to a server
    };

    const wave1Ref = useRef(null);
    const wave2Ref = useRef(null);
    const wave3Ref = useRef(null);

    const contactSectionRef = useRef(null);

    useEffect(() => {
        const currentContactSection = contactSectionRef.current;
        // Define GSAP timelines for each wave with yoyo effect for back and forth animation
        const wave1Timeline = gsap.timeline({ paused: true, repeat: -1, yoyo: true })
            .to(wave1Ref.current, { x: '+=40', duration: 3, ease: 'sine.inOut' });
        const wave2Timeline = gsap.timeline({ paused: true, repeat: -1, yoyo: true })
            .to(wave2Ref.current, { x: '-=20', duration: 3, ease: 'sine.inOut' });
        const wave3Timeline = gsap.timeline({ paused: true, repeat: -1, yoyo: true })
            .to(wave3Ref.current, { x: '+=10', duration: 2, ease: 'sine.inOut' });

        // Function to start animations
        const startAnimations = () => {
            wave1Timeline.play();
            wave2Timeline.play();
            wave3Timeline.play();
        };

        // Function to stop animations
        const stopAnimations = () => {
            wave1Timeline.pause();
            wave2Timeline.pause();
            wave3Timeline.pause();
        };

// Intersection Observer to play/pause animations based on visibility
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        startAnimations();
                    } else {
                        stopAnimations();
                    }
                });
            },
            { threshold: 0.5 } // Adjust threshold to when you want the animation to start/stop
        );

        if (currentContactSection && currentContactSection.getBoundingClientRect().top < window.innerHeight) {
            startAnimations();
        }

        return () => {
            stopAnimations(); // Ensure animations are stopped to prevent memory leaks
            if (currentContactSection) {
                observer.unobserve(currentContactSection);
            }
        };
    }, []);

    return (
        <section id="contact" ref={contactSectionRef}>
            <ContactForm onSubmit={handleFormSubmit} />
            <div className="custom-shape-divider-bottom-1708508797">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path ref={wave1Ref} d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity="1" className="shape-fill-yellow"></path>
                    <path ref={wave2Ref} d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity="1" className="shape-fill-pink"></path>
                    <path ref={wave3Ref} d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" className="shape-fill-beige"></path>
                </svg>
            </div>
        </section>
    );
};

export default Contact;
