import React from 'react';

const Work = () => {
    return (
        <section id="work">
            <h1>My Portfolio section</h1>
        </section>
    );
};

export default Work;