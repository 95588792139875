import React, { useRef, useEffect } from 'react';
import liliana from './img/lilianacard.png';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import FlowerSvg from './components/svg/FlowerSvg';

gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const daisyRef = useRef(null);
  const cardRef = useRef(null); // Reference to the card
  // map function for calculating rotation and brightness
  const map = (value, inMin, inMax, outMin, outMax) => {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  };

  useEffect(() => {
    ScrollTrigger.create({
      trigger: '#about',
      start: 'top bottom',
      end: 'bottom top',
      scrub: true,
      onUpdate: self => {
        const scrollProgress = self.progress;
        const rotationDegrees = scrollProgress * 360;
        gsap.to(daisyRef.current, { rotation: rotationDegrees });
      },
    });

    const onMouseMove = event => {
      // Adjust to directly use event properties
      const { offsetX, offsetY, target } = event;
      const { width, height } = target.getBoundingClientRect();

      const rotateY = map(offsetX, 0, width, -25, 25);
      const rotateX = map(offsetY, 0, height, 25, -25);
      const brightness = map(offsetY, 0, height, 1.5, 0.5);

      gsap.to(cardRef.current, {
        rotationY: rotateY,
        rotationX: rotateX,
        ease: 'ease-out',
        transformPerspective: 600,
        filter: `brightness(${brightness})`,
      });
    };

    const onMouseEnter = () => {
      gsap.to(cardRef.current, {
        scale: 1.3,
        duration: 0.5,
        ease: 'power1.out',
      });
    };

    const onMouseLeave = () => {
      gsap.to(cardRef.current, {
        rotationY: 0,
        rotationX: 0,
        scale: 1,
        ease: 'power1.out',
        transformPerspective: 800,
        filter: 'brightness(1)',
      });
    };

    // Ensure the element exists and attach event listeners
    const cardElement = cardRef.current;
    if (cardElement) {
      cardElement.addEventListener('mousemove', onMouseMove);
      cardElement.addEventListener('mouseenter', onMouseEnter);
      cardElement.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      ScrollTrigger.killAll();
      if (cardElement) {
        cardElement.removeEventListener('mousemove', onMouseMove);
        cardElement.addEventListener('mouseenter', onMouseEnter);
        cardElement.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, []);

  return (
    <section id='about'>
      {/*<DaisySvg ref={daisyRef} />*/}
      <FlowerSvg ref={daisyRef} />
      <h1>Liliana Summers</h1>
      <div className='container'>
        <div className='col'>
          <div className='card3d' ref={cardRef}>
            <img src={liliana} alt='Liliana Summers Web Developer' />
            {/*<div className='card__glare'></div>*/}
          </div>
        </div>
        <div className='col'>
          <p>
            Olá! I'm Liliana, a creative web developer whose journey began
            amidst the vibrant landscapes of Portugal and has flourished in
            Melbourne's dynamic pulse, Australia. Tinkering in the digital realm
            from an early age, I formally embarked on my web development voyage
            in 2016. This journey has been a bridge between continents and
            cultures, enabling me to craft web experiences that are not only
            innovative but ethically grounded.
          </p>
          <p>
            When I'm not coding a greener future into the web, I'm likely
            embracing the great outdoors, capturing life through my camera, or
            at my sewing table, crafting heartfelt creations for friends and
            family. Each hobby weaves into the fabric of my life, reflecting the
            diversity of my interests and the depth of my creativity.
          </p>
          <p>
            With 8 years of experience, I merge Portuguese warmth with Aussie
            ingenuity, crafting web solutions that resonate with hearts
            worldwide and spur action for a better tomorrow. Join me on this
            journey, as we transform digital dreams into planet-friendly
            realities.
          </p>
        </div>
      </div>
      <div className='custom-shape-divider-bottom-1708657878'>
        <svg
          data-name='Layer 1'
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 1200 120'
          preserveAspectRatio='none'
        >
          <path
            d='M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z'
            className='shape-fill-beige'
          ></path>
        </svg>
      </div>
    </section>
  );
};

export default About;
