import React from 'react';

const FlowerSvg = React.forwardRef((props, ref) => {
  return (
    <svg
      className='flower-svg'
      viewBox='0 0 80 80'
      xmlns='http://www.w3.org/2000/svg'
      ref={ref}
    >
      <path
        d='M5.3,49.3c-1.85,3.3-1.85,7.39.06,10.7,1.91,3.31,5.45,5.35,9.24,5.4.05,3.78,2.09,7.33,5.4,9.24,3.31,1.91,7.4,1.91,10.7.06,1.94,3.25,5.47,5.3,9.3,5.3s7.36-2.05,9.3-5.3c3.3,1.85,7.39,1.85,10.7-.06,3.31-1.91,5.35-5.45,5.4-9.24,3.78-.05,7.33-2.09,9.24-5.4s1.91-7.4.06-10.7c3.25-1.94,5.3-5.47,5.3-9.3s-2.05-7.36-5.3-9.3c1.85-3.3,1.85-7.39-.06-10.7-1.91-3.31-5.45-5.35-9.24-5.4-.05-3.78-2.09-7.33-5.4-9.24-3.31-1.91-7.4-1.91-10.7-.06-1.94-3.25-5.47-5.3-9.3-5.3s-7.36,2.05-9.3,5.3c-3.3-1.85-7.39-1.85-10.7.06-3.31,1.91-5.35,5.45-5.4,9.24-3.78.05-7.33,2.09-9.24,5.4s-1.91,7.4-.06,10.7c-3.25,1.94-5.3,5.47-5.3,9.3s2.05,7.36,5.3,9.3Z'
        style={{ fill: '#deafcd', strokeWidth: 0 }}
      />
      <path
        d='M7.14,31.99c.27-.12.47-.36.55-.65.08-.29.02-.59-.15-.84-1.98-2.81-2.16-6.54-.45-9.51,1.71-2.97,5.03-4.68,8.45-4.37.3.03.59-.08.8-.29s.32-.5.29-.8c-.31-3.42,1.4-6.74,4.37-8.45,2.97-1.71,6.7-1.54,9.51.45.24.17.55.22.84.15.29-.08.52-.28.65-.55,1.44-3.12,4.58-5.14,8.01-5.14s6.57,2.02,8.01,5.14c.12.27.36.47.65.55.29.08.59.02.84-.15,2.81-1.98,6.54-2.16,9.51-.45,2.97,1.71,4.68,5.03,4.37,8.45-.03.3.08.59.29.8.21.21.51.32.8.29,3.42-.31,6.74,1.4,8.45,4.37,1.71,2.97,1.54,6.7-.45,9.51-.17.24-.23.55-.15.84.08.29.28.52.55.65,3.12,1.44,5.14,4.58,5.14,8.01s-2.02,6.57-5.14,8.01c-.27.12-.47.36-.55.65-.08.29-.02.59.15.84,1.98,2.81,2.16,6.54.45,9.51-1.71,2.97-5.03,4.68-8.45,4.37-.29-.03-.59.08-.8.29-.21.21-.32.5-.29.8.31,3.42-1.4,6.74-4.37,8.45-2.97,1.71-6.7,1.54-9.51-.45-.24-.17-.55-.23-.84-.15-.29.08-.52.28-.65.55-1.44,3.12-4.58,5.14-8.01,5.14s-6.57-2.02-8.01-5.14c-.12-.27-.36-.47-.65-.55-.09-.02-.17-.03-.26-.03-.2,0-.41.06-.58.18-2.81,1.98-6.54,2.16-9.51.45-2.97-1.71-4.68-5.03-4.37-8.45.03-.3-.08-.59-.29-.8-.21-.21-.5-.31-.8-.29-3.43.31-6.74-1.4-8.45-4.37-1.71-2.97-1.54-6.7.45-9.51.17-.24.23-.55.15-.84-.08-.29-.28-.52-.55-.65-3.12-1.44-5.14-4.58-5.14-8.01s2.02-6.57,5.14-8.01Z'
        style={{ fill: '#deafcd', strokeWidth: 0 }}
      />
      <circle
        cx='40'
        cy='40'
        r='10.82'
        style={{ fill: '#f2cd51', strokeWidth: 0 }}
      />
      <circle
        cx='40'
        cy='40'
        r='8.82'
        style={{ fill: '#f2cd51', strokeWidth: 0 }}
      />
    </svg>
  );
});

export default FlowerSvg;
